<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac flex-w">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">系列名称：</span>
						<el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否结束：</span>
						<el-select class="w-250" v-model="retrieveForm.isGq" clearable placeholder="请选择">
							<el-option label="正执行" :value="0">
							</el-option>
							<el-option label="已结束" :value="1">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否上架：</span>
						<el-select class="w-250" v-model="retrieveForm.shelves" clearable placeholder="请选择">
							<el-option v-for="item in shelves" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="getArea" v-if="helpTitle == '助力活动'">助力区间</div> -->
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="returnActivity" v-if="helpTitle == '助力区间'">返回</div> -->
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="activityColumns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:helpCoverImg="scope">
					<img :src="scope.row.helpCoverImg" alt="" class="smallImg" v-if="scope.row.helpCoverImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 弹框 -->
		<!-- <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="15vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <template v-if="helpTitle == '助力活动'">
          <el-form-item label="能量值" prop="energy" v-if="'energy' in ruleForm"
            :rules="{required: true, message: '能量值不能为空', trigger: 'blur'}">
            <el-input type="number" v-model="ruleForm.energy"></el-input>
          </el-form-item>
          <el-form-item label="关联系列" prop="seriesId" v-if="'seriesId' in ruleForm"
            :rules="{required: true, message: '请至少选择一个系列', trigger: 'change'}">
            <el-select v-model="ruleForm.seriesId" placeholder="请选择" :disabled="dialogTitle=='新增'?false:true">
              <el-option v-for="item in seriesList" :key="item.id" :label="item.seriesName" :value="item.id"
                v-if="item.value != 1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期" prop="startDate" v-if="'startDate' in ruleForm"
            :rules="{required: true, message: '开始日期不能为空', trigger: 'blur'}">
            <el-date-picker v-model="ruleForm.startDate" type="datetime" placeholder="选择开始日期"
              value-format="yyyy-MM-dd HH:mm:ss" :disabled="dialogTitle=='新增'?false:true">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期" prop="endDate" v-if="'endDate' in ruleForm"
            :rules="{required: true, message: '结束日期不能为空', trigger: 'blur'}">
            <el-date-picker v-model="ruleForm.endDate" type="datetime" placeholder="选择结束日期"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="领取人数" prop="drawNum" v-if="'drawNum' in ruleForm">
            <el-input type="number" v-model="ruleForm.drawNum"></el-input>
          </el-form-item>
        </template>
        <template v-if="helpTitle == '助力区间'">
          <el-form-item label="开始区间" prop="startArea" v-if="'startArea' in ruleForm">
            <el-input type="number" v-model="ruleForm.startArea"></el-input>
          </el-form-item>
          <el-form-item label="结束区间" prop="endArea" v-if="'endArea' in ruleForm">
            <el-input type="number" v-model="ruleForm.endArea"></el-input>
          </el-form-item>
          <el-form-item label="新用户能量" prop="newEnergy" v-if="'newEnergy' in ruleForm">
            <el-input type="number" v-model="ruleForm.newEnergy"></el-input>
          </el-form-item>
          <el-form-item label="老用户能量" prop="oldEnergy" v-if="'oldEnergy' in ruleForm">
            <el-input type="number" v-model="ruleForm.oldEnergy"></el-input>
          </el-form-item>
        </template>
      </el-form>
<      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div> -->
		<!-- </el-dialog> -->
		<!-- 新增弹框 -->
		<help-activity-details ref="help-activity-details" @refresh="getTableData"></help-activity-details>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import axios from "axios"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivity',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"help-activity-details": (resolve) => require(["@/views/modules/activity/helpActivityDetails"], resolve),
		},
		computed: {
			...mapState('menu', {
				'shelves': state => state.dictList ? state.dictList.shelves : [], //是否上下架
			})
		},
		data() {
			return {
				helpTitle: '助力活动',
				retrieveForm: {
					seriesName: '',
					isGq: '',
					shelves: ''
				},
				tabLoading: false,
				butLoading: false,
				ranks: [{
						actyId: 1,
						actyType: 1001,
						id: 1,
						passingLine: 2,
						props: [{
							actyRankId: 1
						}, {
							couponsType: 1
						}, ]
					}, {
						actyId: 2,
						actyType: 1002,
						id: 2,
						passingLine: 3,
						props: [{
							actyRankId: 1
						}, {
							couponsType: 1
						}, ]
					}
					// ... 其他 rank 和 props 的组合
				],
				//活动列表
				activityColumns: [{
						type: 'index',
						label: '序号',
						// width: '60',
						align: 'center',
						index: this.indexMethod
					}, {
						label: '系列名称',
						prop: 'helpActyName',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '价格',
						prop: 'price',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						columnType: 'custom',
						label: '封面图',
						prop: 'helpCoverImg',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '领取人数',
						// width:'80',
						prop: 'drawNum',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '上下架',
						// width:'80',
						prop: 'shelves',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							let ele = this.shelves.find(ele => ele.value == e)
							return ele ? ele.key : 'eee'
						}
					},
					// {
					//      columnType: 'fun',
					//      label: '关联系列',
					//      prop: 'seriesName',
					//      align: 'left',
					//      'show-overflow-tooltip': true,
					//      formatData: (e) => {
					//        let ele = this.seriesList.find(ele => ele.id == e)
					//        return ele ? ele.seriesName : e
					//      }
					//    }, 
					{
						label: '开始时间',
						prop: 'startDate',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '结束时间',
						prop: 'endDate',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '150',
						buttons: [{
							type: 'text',
							text: '编辑',
							fn: this.editList,
						}, {
							type: 'text',
							text: '复制',
							fn: this.copyList,
						}, {
							type: 'text',
							text: '删除',
							fn: this.deleteList,
						}, ],
					},
				],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					//助力活动
					helpActyName: '',
					helpCoverImg: '',
					energy: '',
					seriesId: '',
					startDate: '',
					endDate: '',
					drawNum: '',
					//助力区间
					startArea: '',
					endArea: '',
					newEnergy: '',
					oldEnergy: '',
				},
				seriesList: [], //系列列表
			}
		},
		mounted() {
			// this.helpTitle = '助力活动'
			this.getTableData()
			// this.findBoxSeries()
			// this.testSave()
		},
		methods: {
			//搜索
			submitSearch() {
				this.getTableData()
			},
			//助力区间
			getArea() {
				this.helpTitle = '助力区间'
				this.getTableData()
			},
			//返回
			returnActivity() {
				this.helpTitle = '助力活动'
				this.getTableData()
			},
			//获取表格数据
			getTableData() {
				this.$http.get('/helpActy/findHelpActy', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						seriesName: this.retrieveForm.seriesName,
						isGq: this.retrieveForm.isGq,
						shelves: this.retrieveForm.shelves,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch((err) => {})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					if (this.helpTitle == '助力活动') {
						this.$http.post('/helpActy/removeHelpActy', {
							actyId: row.id
						}).then(({
							data: result
						}) => {
							this.getTableData()
							this.$message({
								message: '删除成功',
								type: 'success'
							})
						})
					} else if (this.helpTitle == '助力区间') {
						this.$http.post('/helpArea/removeHelpArea', {
							id: row.id
						}).then(({
							data: result
						}) => {
							this.getTableData()
							this.$message({
								message: '删除成功',
								type: 'success'
							})
						})
					}
				}).catch(_ => {})
			},
			//新增列表
			addList() {
				this.$refs['help-activity-details'].init()
			},
			//跳转详情
			editList(row) {
				// this.dialogFormVisible = true
				this.$refs['help-activity-details'].init(row.id)
			},
			//复制
			copyList(row) {
				this.$confirm('您确认复制吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/helpActy/copyHelpActy', {
						helpId: row.id
					}).then(res => {
						this.getTableData()
					})
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>